<template>
  <b-row>
    <b-col class="text-center">
      <p class="small" style="margin-top: 80px; margin-bottom: 50px; opacity: 0.5;">Whoops! Page not found.</p>
    </b-col>
  </b-row>
</template>

<script>

export default {
  name: "catch-all",

  metaInfo: {
    title: 'Page not Found'
  },

};

</script>

